import apiClient from "api";

export const client = {
  parse: async (message: string): Promise<ServiceResponse<ParsedMovement>> =>
    apiClient.retrieve<ParsedMovement>({
      url: "/movement/parse",
      method: "post",
      payload: {message},
    }),
  add: async (movement: CreateMovement): Promise<ServiceResponse<Movement>> =>
    apiClient.retrieve<Movement>({
      url: "/movement",
      method: "post",
      payload: movement,
    }),
  store: async (
    movement: CreateMovement | Movement
  ): Promise<ServiceResponse<Movement>> =>
    apiClient.retrieve<Movement>({
      url: "/movement",
      method: "post",
      payload: movement,
    }),
  filter: async ({
    range,
  }: {
    range?: [Date, Date];
  }): Promise<ServiceResponse<MovementStats>> =>
    apiClient.retrieve<MovementStats>({
      url: `/movements?range=${JSON.stringify(range)}`,
      method: "get",
    }),
  stats: async (): Promise<ServiceResponse<UserConcept[]>> =>
    apiClient.retrieve<UserConcept[]>({
      url: "/movements/stats",
      method: "get",
    }),
};

export default client;
